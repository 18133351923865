.cloud {
  position: relative;
  width: 250px;
  height: 90px;
  background: transparent;
  overflow: hidden;

  .bigCircle {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0, 50%);
    border-radius: 50%;
    height: 180px;
    width: 180px;
    background: #ebfff5;
  }

  .smallCircle {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(0, 50%);
    border-radius: 50%;
    height: 120px;
    width: 120px;
    background: #ebfff5;
  }
}