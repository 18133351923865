.topBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: -406px;
  right: 0;
  left: 0;
  height: 210px;
  z-index: 2;
  background-color: #1e3262;
  padding: 64px 84px 132px;
  transition: top ease-in 0.4s;

  &.visible {
    top: 0;
  }

  .topBarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      left: 0;
      height: 55px;
    }

    .closeIconContainer {
      position: relative;
      right: 0;
      width: 47px;
      height: 47px;
      border-radius: 50%;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 24px;
    width: calc(100vw - 48px);

    &.visible {
      right: 0;
      left: 0;
    }
  }
}