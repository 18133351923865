.newsContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(30,50,98,1) 50%, rgba(2,0,36,1) 100%);
  padding: 96px 48px 48px;

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .header {
      font-size: 48px;
      color: #f9ac34;
      font-weight: bolder;
      margin: 48px 0;
    }

    .slogan {
      font-size: 36px;
      color: white;
      text-align: start;
    }

    .logo {
      height: 96px;
    }
  }


  .news {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 5px;
    row-gap: 150px;
    max-width: 100%;
    padding: 40px;

    .newsItem {
      position: relative;
      width: 300px;
      height: 200px;
      margin: 0 0 10px 0;

      cursor: pointer;
      background: white;
      border-radius: 4px;

      img {
        position: absolute;
        left: 30px;
        top: -50%;
        max-height: 175px;
        min-width: calc(100% - 60px);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 17px -3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0 6px 17px -3px rgba(0,0,0,0.75);
        box-shadow: 0 6px 17px -3px rgba(0,0,0,0.75);
        transition: 0.2s all ease;

        &:hover {
          transform: scale(1.02);
        }
      }

      .content {
        position: absolute;
        bottom: 0;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 40%;
        width: calc(100% - 32px);
        text-align: center;

        .title {
          font-family: 'Roboto';
          font-weight: 900;
          color: #1e3262;
          font-size: 20px;
        }

        .description {
          font-family: 'Roboto';
          font-size: 14px;
          margin: 12px 0 0;
          color: #1e3262;
          text-align: start;
        }
      }

      .description {
        font-family: 'Roboto';
        font-size: 14px;
        margin: 12px 0 0;
        color: #646464;
      }

      &:after {
        opacity: 0;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-right: 1px white solid;
        border-bottom: 1px white solid;
        width: 0;
        height: 0;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        -webkit-box-shadow: 0 0 20px 2px rgb(0 0 0 / 1);
        -moz-box-shadow: 0 0 20px 2px rgb(0 0 0 / 1);
        box-shadow: 0 0 20px 2px rgb(0 0 0 / 1);
        transition: .4s all ease;
        background: white;
        z-index: 1;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: solid 0 white;
        border-left: solid 0 rgba(0, 0, 0, 0.04);
        transition: .4s all ease;
        z-index: 2;
      }

      &:hover {

        img {
          transform: scale(1.08);
        }

        &:after {
          opacity: 1;
          width: 20px;
          height: 20px;
        }

        &:before {
          border-top: solid 20px white;
          border-left: solid 20px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    .news {
      grid-template-columns: auto auto;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;

    .news {
      margin-top: 120px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;

    .news {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-top: 120px;

      .newsItem {
        width: 250px;
        height: 165px;

        img {
          width: calc(100% - 75px);
        }
      }
    }
  }
}
