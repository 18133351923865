.contact {
  border-top: solid 1px #DDDDDD;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 15px;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  .phone {
    font-size: 19px;
    font-weight: 500;
    color: #000;
  }

  .email {
    font-size: 16px;
    font-weight: 400;
    color: #484848;
    margin-top: 10px;
  }

  .address {
    font-size: 16px;
    font-weight: 400;
    color: #484848;
    margin-top: 10px;
  }

  &.white {
    margin-top: 0;
    margin-bottom: 20px;
    padding-top: 0;
    border: none;

    .email {
      color: white;
    }
    .phone {
      color: white;
    }
    .address {
      color: white;
    }
  }
}