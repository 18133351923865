.specialty {
  position: relative;
  background-color: white;
  padding: 65px 0 55px;
  border-bottom: 1px solid #ddd;
  border-right: 0.25px solid #ddd;
  border-left: 0.25px solid #ddd;
  transition: all ease-in 0.3s;

  &:last-child {
    border-right: none;
  }

  &:first-child {
    border-left: none;
  }

  img {
    height: 62px;
    opacity: 0.4;
  }

  .title {
    color: #aaa;
    font-weight: bold;
  }

  .description {
    color: #1e3262;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0) translate(-50%, -50%);
    transition: transform ease-in 0.3s;
  }

  &:hover {
    z-index: 1;
    transform: scale(1.25);
    -webkit-box-shadow: 0 1px 5px 0 rgb(204 204 204);
    -moz-box-shadow: 0 1px 5px 0 rgb(204 204 204);
    box-shadow: 0 1px 5px 0 rgb(204 204 204);

    &:last-child {
      margin-right: 40px;
    }

    &:first-child {
      margin-left: 40px;
    }

    img, .title {
      filter: blur(3px);
    }

    .description {
      transform: scale(1) translate(-50%, -50%);
    }
  }

  @media only screen and (max-width: 768px) {
    &:hover {
      transform: scale(1);
      margin: 0!important;
      box-shadow: none;
    }
  }
}