.imageContainer {
  height: 100vh;

  img {
    min-height: 100%;
    min-width: 100%;
  }

  .sector {
    position: absolute;
    top: 26%;
    left: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
    font-weight: bold;
    z-index: 1;
    letter-spacing: 4px;
  }

  .slogan {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 90px;
    font-weight: 700;
    letter-spacing: -3px;
    line-height: 1em;
    z-index: 1;
  }

  .whoWeAre {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(41, 72, 208);
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: 54px;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 40px;
    padding-right: 40px;
    border: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: rgb(21, 48, 168);
    }
  }

  @media only screen and (max-height: 850px) {
    width: 100vw;

    img {
      max-width: 100vw;
    }
    .sector {}

    .slogan {
      font-size: 48px;
      letter-spacing: 0;
      line-height: 1.25em;
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    img {
      max-width: 100vw;
    }
    .sector {}

    .slogan {
      font-size: 48px;
      letter-spacing: 0;
      line-height: 1.25em;
      width: 100%;
    }
  }
}

.circle {
  border: 5px solid red;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}