.comments {
  position: relative;
  padding: 120px 0;

  .imageContainer {
    height: 100vh;

    img {
      min-height: 100%;
      min-width: 100%;
    }
  }

  .slick-dots {
    bottom: -64px;

    li {
      width: 5px;
      height: 5px;
      margin: 0 16px;
    }

    li.slick-active button {
      background-color: #f9ac34;
      border-color: #f9ac34;
      opacity: 1;
    }

    li button {
      border: 2px #eee solid;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      padding: 3px;

      &:before {
        content: none;
      }
    }
  }


  .slick-arrow {
    z-index: 1;
    height: 20px;
    width: 20px;
    background: #eeeeee;
    border-radius: 50%;
    padding: 18px;
  }

  .slick-prev {
    left: 64px;
  }

  .slick-next {
    right: 64px;
  }
}