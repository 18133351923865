.leafContainer {
  position: relative;
  width: 9vh;
  height: 9vh;
  margin-bottom: 24px;
  z-index: 0;

  .content {
    opacity: 0;
    position: absolute;
    bottom: 2vh;
    left: 16vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    width: 25vh;
    transition: all 1s ease-in;
    cursor: pointer;

    .image {
      position: relative;
      width: 8vh;
      height: 8vh;
      background: #f9ac34;
      border-radius: 50%;
      margin-bottom: 12px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        height: 70%;
        transform: translate(-50%, -50%);
      }
    }

    .title {
      font-weight: bolder;
      color: #1e3262;
      margin-bottom: 4px;
    }

    .description {
      color: #1e3262;
    }
  }

  .leaf {
    position: relative;
    background-color: green;
    height: 9vh;
    width: 9vh;
    border-radius: 9vh 0;
    margin-left: 1vh;
    transform: scale(0);
    transition: all ease-in 2s;

    .branch {
      position: absolute;
      left: -1vh;
      bottom: -1vh;
      border-bottom: 1px solid #1e3262;
      width: 9vh;
      height: 9vh;
      transform: rotate(-45deg);
      transform-origin: bottom left;
    }

  }

  &.reverse {

    .content {
      align-items: flex-end;
      text-align: end;
      left: -16vh;
      transform: translateX(-100%);
    }

    .leaf {
      transform: rotate(90deg) scale(0);
      margin-left: -10vh;

      .branch {
        left: unset;
        right: -3.7vh;
        top: -3.7vh;
        bottom: unset;
      }
    }
  }

  &.grown {
    .content {
      opacity: 1;
      transition: all 3s ease-in;
    }
    .leaf {
      transform: scale(1);
    }

    &.reverse {
      .leaf {
        transform: rotate(90deg) scale(1);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      width: 12vh;
      align-items: center;
      text-align: center;
      left: 10vh;
    }

    &.reverse {
      .content {
        left: -10vh;
        align-items: center;
        text-align: center;
      }
    }
  }
}
