.topServices {
  position: relative;
  padding: 120px 0 0;

  .title {
    position: absolute;
    top: 120px;
    left: 64px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.8px;
    line-height: 18px;
    color: #1e3262;
  }

  .description {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 60px;
    padding: 24px 97px 0 64px;
    text-align: start;

    .slogan {
      font-size: 57.001px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 57px;
      color: #1e3262;
    }

    .explanation {
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 28.56px;
      color: #1e3262;
    }
  }


  .services {
    display: grid;
    padding: 0 124px 0 180px;
    margin-bottom: 48px;

    .service {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      padding: 24px 0 0 0;
      overflow: hidden;

      &:not(:last-child) {
        border-right: 2px #eeeeee solid;
        margin-right: 48px;
      }

      .icons {
        height: 64px;
        margin-bottom: 40px;
      }

      .text {
        text-align: start;
        width: 60%;
        font-size: 23.987px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 28.7844px;
        margin-bottom: 124px;
        color: #1e3262;
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        position: absolute;
        bottom: 24px;
        left: -84px;
        transition: all 0.3s ease-in;

        img {
          width: 20px;
          height: 32px;
          margin-left: 12px;
        }

        .readMore {
          opacity: 0;
          font-weight: 600;
          transition: all 0.3s ease-in;
          color: #1e3262;
        }
      }

      &:hover {

        .arrow {
          bottom: 24px;
          left: 0;

          .readMore {
            opacity: 1;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .services {
      padding: 0;
      margin-bottom: 0;
      grid-template-columns: 50% 50%!important;

      .service {
        padding: 24px 0;
        align-items: center;
        margin: 0!important;
        border-bottom: 1px solid #eee;

        .icons {
          margin: 0;
          height: 48px;
        }

        .text {
          text-align: center;
          font-size: 16px;
          margin: 24px 0 64px;
        }

        .arrow {
          flex-direction: column-reverse;
          position: absolute;
          left: unset;
          bottom: 24px;

          .readMore {
            color: #484848;
            margin: 0;
            font-size: 12px;
            opacity: 1;
          }

          img {
            margin: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 64px 0 0;

    .title {
      top: 64px;
      left: 24px;
    }

    .description {
      display: flex;
      flex-direction: column;
      padding: 24px 24px 0 24px;
      margin: 0 0 24px;

      .slogan {
        font-size: 32px;
      }

      .explanation {
        font-size: 16px;
      }
    }

    .services {
      padding: 0;
      margin-bottom: 0;
      grid-template-columns: 50% 50%!important;

      .service {
        padding: 24px 0;
        align-items: center;
        margin: 0!important;
        border-bottom: 1px solid #eee;

        .icons {
          margin: 0;
          height: 48px;
        }

        .text {
          text-align: center;
          font-size: 16px;
          margin: 24px 0 64px;
        }

        .arrow {
          flex-direction: column-reverse;
          position: absolute;
          left: unset;
          bottom: 24px;

          .readMore {
            color: #484848;
            margin: 0;
            font-size: 12px;
            opacity: 1;
          }

          img {
            margin: 0;
          }
        }
      }
    }
  }
}

