.scrollButton {
  position: fixed;
  right: 32px;
  border: 1px #101F44FF solid;
  background: #101F44FF;
  border-radius: 50%;
  min-height: 43px;
  min-width: 43px;
  cursor: pointer;
  bottom: -45px;
  transition: bottom ease-in 0.3s;

  img {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
  }

  &.visible {
    bottom: 32px;
    z-index: 1;
  }

  &:hover {
    border-color: #f9ac34;
    background-color: #f9ac34;
  }

  @media only screen and (max-width: 768px) {
    min-height: 33px!important;
    min-width: 33px!important;
    right: 15px!important;
    bottom: 15px!important;
    border: none;

    img {
      width: 16px;
    }
  }
}