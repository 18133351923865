.closeButton {
  position: absolute;
  cursor: pointer;
  transition: all ease-in 0.25s;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.white {
    width: 24px;
    height: 24px;
  }

  &:hover {
    transform: rotate(180deg) translate(50%, 50%);
  }
}