.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 110px;
  background: #1e3262;
  padding: 0 40px;

  .copyright {
    color: white;

    span {
      color: #f9ac34;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 52px;
  }

  @media only screen and (max-width: 768px) {
    padding: 25px 0;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;

    .logo {
      position: relative;
      left: unset;
      transform: unset;
      margin: 20px 0;
    }
  }
}