.searchInput {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #b0b3bc;

  input {
    background: transparent;
    border: none;
    outline: none;
    font-size: 32px;
    line-height: normal;
    font-weight: 300;
    height: 92px;
    width: 100%;
    letter-spacing: 0;
    margin: 0;
    color: #b0b3bc;
  }

  img {
    position: absolute;
    padding: 10.5px;
    width: 26px;
    height: 26px;
    right: 0;
  }
}