.socialMediaList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;

  li {
    list-style: none;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #000;
      font-weight: 600;
      font-size: 20px;

      img {
        height: 20px;
        margin-right: 24px;
      }

      p {
        margin: 0;
        line-height: 0;
      }

      &:hover {
        color: #CCCCCC;
      }
    }

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  &.vertical {
    flex-direction: row;
    position: unset;
    top: unset;
    transform: unset;
    margin-right: 45px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 50%;
      min-height: 43px;
      min-width: 43px;
      margin: 0 5px 0 0;
      cursor: pointer;

      &:hover {
        background-color: #040817;
      }

      &:active {
        background-color: #f9ac34;
      }

      a {

        img {
          margin: 0;
        }
      }

      p {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 0!important;

    li {
      min-width: 32px!important;
      min-height: 32px!important;

      a {
        img {
          height: 16px;
        }
      }
    }
  }
}