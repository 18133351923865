.navBar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 0;
  margin: 0;
  height: 100%;

  .navBarItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    list-style: none;
    cursor: pointer;
    margin-right: 12px;

    a {
      font-weight: 600;
      text-decoration: none;
      color: #484848;
    }

    .stroke {
      width: 0;
      margin-top: 4px;
      height: 1.5px;
      border-radius: 8px;
      background-color: #484848;
      transition: width ease-in 0.2s;

      &.active {
        width: 100%;
      }
    }

    .subItems {
      position: absolute;
      background-color: #1e3262;
      top: 70px;
      left: 0;
      padding: 15px 0;
      width: 200px;
      display: none;

      li {
        list-style: none;
        padding: 10px 12px;
        margin: 0;
        text-align: start;
        transition: padding ease-in 0.2s;
        width: fit-content;

        a {
          color: #bbb;
          transition: color ease-in 0.2s;
        }

        .stroke {
          width: 0;
          margin-top: 4px;
          height: 1.5px;
          border-radius: 8px;
          background-color: #eee;
          transition: width ease-in 0.2s;
        }

        &:hover {
          padding: 10px 12px 10px 24px;

          a {
            color: #ffffff;
          }

          .stroke {
            width: 100%;
          }
        }
      }
    }

    &:hover {
      .subItems {
        display: block;
      }

      .stroke {
        width: 100%;
      }
    }
  }

  &.reverse {
    .navBarItem {
      a {
        color: white;
      }

      .stroke {
        background-color: white;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    position: unset;
    left: unset;
    transform: unset;
    margin: 0;
  }

  @media only screen and (max-width: 576px) {
    font-size: 12px;
  }
}