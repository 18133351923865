.contactUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #0e173b;
  padding: 120px 12% 120px 15%;
  color: white;
  overflow: hidden;

  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.8px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 16px;
    z-index: 1;
  }

  .question {
    font-size: 57.001px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 57.001px;
    z-index: 1;
  }

  .slogan {
    font-size: 57.001px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 57.001px;
    margin-bottom: 30px;
    z-index: 1;
  }

  form {
    width: 100%;
    z-index: 1;

    .row {
      display: flex;

      &:last-child {
        margin-bottom: 44px;
      }

      .inputNumber::-webkit-outer-spin-button,
      .inputNumber::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .inputNumber[type=number] {
        -moz-appearance: textfield;
      }
    }

    .formFooter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .buttonContainer {
        display: flex;
        align-items: center;

        button {
          position: relative;
          cursor: pointer;
          padding: 17px 35px 17px 64px;
          border: none;
          outline: none;
          background: #f9ac34;
          font-weight: 600;
          color: white;
          margin-right: 15px;
          
          img {
            left: 32px;
            position: absolute;
            width: 16px;
          }
        }
      }

      .messageStatus {
        margin-top: 15px;

        span {
          color: #f2f2f2;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 32px 6% 120px;

    .title {
      font-size: 12px;
    }

    .question {
      font-size: 24px;
    }

    .slogan {
      font-size: 24px;
    }
  }
}