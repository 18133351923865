.services {
  width: 100%;
  display: grid;
  grid-template-columns: 33.33333% 33.33333% 33.33333%;

  .imageContainer {
    position: relative;
    cursor: pointer;
    width: 100%;
    overflow: hidden;

    .text {
      position: absolute;
      color: white;
      font-size: 32px;
      font-weight: bold;
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease-in;
    }

    .arrow {
      color: white;
      position: absolute;
      opacity: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease-in;
      width: 32px;
      height: 32px;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }

      .arrow {
        opacity: 1;
        bottom: 30%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .imageContainer {
      display: flex;

      .arrow {
        opacity: 1;
        bottom: 30%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}