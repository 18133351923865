.sustainability {
  position: relative;
  padding: 120px 0;
  height: calc(100vh - 300px);

  .slogan {
    position: absolute;
    right: 4.5rem;
    top: 4.5rem;
    z-index: 1;
    width: min-content;

    font-size: 4.5rem;
    color: #1e3262;
    font-weight: bolder;
  }

  .cloudOne {
    position: absolute;
    top: 148px;
    left: 96px;
  }

  .cloudTwo {
    position: absolute;
    bottom: 24px;
    right: 24px;
  }

  .cloudThree {
    position: absolute;
    top: 24px;
  }

  .cloudFour {
    position: absolute;
    top: 24px;
    right: 64px;
  }

  .cloudFive {
    position: absolute;
    bottom: 128px;
    left: 128px;
  }

  .cloudSix {
    position: absolute;
    top: 128px;
    right: 256px;
  }


  .slick-dots {
    bottom: -64px;

    li {
      width: 5px;
      height: 5px;
      margin: 0 16px;
    }

    li.slick-active button {
      background-color: #f9ac34;
      border-color: #f9ac34;
      opacity: 1;
    }

    li button {
      border: 2px #eee solid;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      padding: 3px;

      &:before {
        content: none;
      }
    }
  }


  .slick-arrow {
    z-index: 1;
    height: 20px;
    width: 20px;
    background: #eeeeee;
    border-radius: 50%;
    padding: 18px;
  }

  .slick-prev {
    left: 64px;
  }

  .slick-next {
    right: 64px;
  }

  @media only screen and (max-width: 48rem) {
    height: 100vh;

    .slogan {
      top: -2rem;
      right: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
    }
  }
}