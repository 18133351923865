.inputContainer {
  position: relative;
  border-bottom: 1px solid #4d5267;
  width: 100%;
  margin: 0 30px 21px 0;

  input {
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    padding: 10px 0;
    color: white;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.1px;
    line-height: 24px;

    ::placeholder {
      color: #b0b3bc;
      opacity: 1;
    }
  }

  .line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    border-radius: 8px;
    height: 1px;
    background-color: #fff;
    transition: width ease 0.8s;
  }

  &:hover {
    .line {
      width: 100%;
    }
  }

  &:focus-within {
    input::placeholder {
        color: white;
        opacity: 1;
    }

    .line {
      width: 100%;
    }
  }
}