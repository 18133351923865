.sliderContainer {
  position: relative;

  .slick-dots {
    bottom: 64px;

    li {
      margin: 0 8px;
    }

    li.slick-active button:before {
      color: #f9ac34;
      opacity: 1;
    }

    li button:before {
      content: '◼';
      color: white;
      font-size: 15px;
      opacity: 1;
    }
  }

  .slick-arrow {
    z-index: 1;
    height: 64px;
    width: 64px;
  }

  .slick-prev {
    left: 64px;
  }

  .slick-next {
    right: 64px;
  }
}