.treeContainer {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  .tree {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 0;
    transition: all ease-in 2s;

    .head {
      position: relative;
      height: 0;
      width: 0;
      border-radius: 50%;
      background-color: #1e3262;
      transition: all ease-in 1s;

      .bubble {
        position: absolute;
        bottom: 20%;
        right: 15%;
        height: 1.8vh;
        width: 1.8vh;
        border-radius: 50%;
        border: 1px solid white;

        &.second {
          right: 18%;
          bottom: 37%;
        }
      }

      .bigBubble {
        position: absolute;
        bottom: 22%;
        right: 30%;
        height: 2.7vh;
        width: 2.7vh;
        border-radius: 50%;
        border: 1px solid white;
      }
    }

    .body {
      height: 0;
      width: 1px;
      background-color: #1e3262;
      transition: all ease-in 2s;
    }

    &.grown {
      height: 54vh;

      &._4 {
        height: 72vh;
      }
      &._5 {
        height: 90vh;
      }
      &._6 {
        height: 90vh;
      }

      .head {
        height: 18vh;
        width: 18vh;
      }

      .body {
        height: 36vh;

        &._4 {
          height: 54vh;
        }
        &._5 {
          height: 72vh;
        }
        &._6 {
          height: 72vh;
        }
      }
    }
  }
}
