.sideBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  right: -385px;
  width: 285px;
  transition: all ease-in 0.4s;
  background: #f9f9f9;
  padding: 55px 50px;

  &.visible {
    right: 0;
  }

  .sideBarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      left: 0;
      height: 32px;
    }

    .closeIconContainer {
      position: relative;
      right: 0;
      width: 47px;
      height: 47px;
      background: #ffffff;
      border-radius: 50%;
    }
  }

  @media only screen and (max-width: 768px) {
    width: unset;
    height: unset;
    left: calc(100vw + 100px);
    bottom: 0;
    top: 0;

    &.visible {
      right: 0;
      left: 0;
    }
  }
}