.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 123;
  background: white;
  transition: all ease-out 2s;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    .letter {
      font-size: 22em;
      font-family: monospace;
      color: #1e3262;
      font-weight: bold;
    }

    .rudder {
      width: 24em;
      height: 24em;
      -webkit-animation:spin 5s linear infinite;
      -moz-animation:spin 5s linear infinite;
      animation:spin 5s linear infinite;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    @-moz-keyframes spin {
      100% { -moz-transform: rotate(360deg); }
    }

    @-webkit-keyframes spin {
      100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
      }
    }
  }

  .loading {
    margin-top: 72px;
    font-size: 72px;
    font-weight: bolder;
    color: #f9ac34;
  }

  &.transitioned {
    opacity: 0;
  }

  @media only screen and (max-width: 768px) {
    .logo {
      transform: scale(0.25);
    }
  }
}