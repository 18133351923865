.liveCams {
  display: flex;
  justify-content: flex-start;
  padding: 80px 0 0;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 80px);

  .title {
    font-size: 72px;
    text-align: center;
    font-weight: bolder;
    color: #1e3262;
  }

  .terminals {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-top: 64px;

    .terminal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-decoration: none;

      img {
        width: 300px;
        border: 4px solid #1e3262;
        border-radius: 32px;
      }

      .text {
        text-align: center;
        font-family: fantasy;
        font-size: 24px;
        font-weight: bolder;
        margin-top: 12px;
        color: #f9ac34;
      }
    }
  }
}