.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: calc(100vw - 100px);
  height: 70px;
  background: #fff;
  box-shadow: 0 3px 16px rgb(0 0 0 / 8%);
  padding: 0 50px;
  z-index: 2;
  transition: top 0.4s ease-in-out;

  &.reverse {
    background: transparent;
    box-shadow: none;
    border-bottom: 1px solid #bbb;

    .logo {
      height: 48px;
    }

    .menuContainer {
      .joinUs {
        .joinUsButton {
          color: white;
          background-color: transparent;
        }
      }

      .languages {
        color: white;

        option {
          padding: 4px;
          color: #1e3262;
        }
      }
    }
  }

  &.scrollDown {
    top: -71px;
  }

  &.scrollUp {
    top: 0;
  }

  .logo {
    height: 32px;
  }

  .menuContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 100%;

    img {
      cursor: pointer;
    }

    .joinUs {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .joinUsButton {
        position: relative;
        border: none;
        outline: none;
        background-color: #faad33;
        padding: 5px 15px;
        border-radius: 5px;
        color: white;
        font-weight: bolder;
        cursor: pointer;
        margin: 0 30px;
      }

      .joinUsModal {
        display: none;
        position: absolute;
        margin-top: 25px;
        padding: 10px;
        right: -50%;
        background-color: white;
        width: fit-content;
        height: fit-content;

        .joinUsModalActionButton {
          display: flex;
          justify-content: center;
          align-items: center;
          top: 20px;
          height: 40px;
          width: 150px;
          background-color: #faad33;
          color: white;
          text-shadow: 2px 2px 4px #000000;
          margin: 5px;
          padding: 5px;
          background-size: cover;
          background-position: center;
        }

        .joinUsModalActionButton:hover {
          opacity: 80%;
        }

        .joinUsModalActionButtonDriver {
          background-image: url(../../assets/jpg/truckdriver.jpg);
        }

        .joinUsModalActionButtonOwnerOperator {
          background-image: url(../../assets/jpg/owneropetatorsoon.jpg);
          cursor: not-allowed;
        }
      }
    }

    .joinUs:hover {
      .joinUsTitle {
        opacity: 80%;
      }

      .joinUsModal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .languages {
      border: none;
      outline: none;
      background: transparent;
      color: #1e3262;
      font-weight: bolder;
      cursor: pointer;
    }

    .searchIcon {
      width: 26px;
      height: 26px;
      margin-left: 24px;
    }

    .menuIcon {
      width: 20px;
      height: 20px;
      margin-left: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: calc(100vw - 48px);
    height: 46px;
    padding: 12px 24px;

    .logo {
      height: 24px;
    }

    &.reverse {
      .logo {
        height: 32px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
  }
}
