.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 12;

  .layoutImageContainer {
    position: relative;
    width: 80vh;
    height: 80vh;
    border-radius: 50%;
    background: #f9ac34;

    .layoutImage {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 70%;
      transform: translate(-50%, -50%);
    }

    .title {
      position: absolute;
      margin-bottom: 24px;
      top: 15%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);

      text-align: center;
      font-size: 32px;
      color: #1e3262;
      font-weight: bolder;
    }

    .description, {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 30vh;
      width: 50%;
      overflow: auto;

      text-align: center;
      font-size: 20px;
      font-weight: bolder;
      color: #484848;
    }
  }


  .modal {
    position: relative;
    width: 35vw;
    min-height: 20vh;
    max-height: 30vh;
    background-color: white;
    border-radius: 2px;
    padding: 6vh 4vw 8vh 7.5vw;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, 25%);
      width: 12vw;
      height: 20vh;
      border: 2px solid #fff;
      background: #fff;
      border-radius: 32px;
      overflow: hidden;

      img {
        min-width: 100%;
        height: 100%;
      }
    }

    .title {
      text-align: start;
      font-size: 24px;
      color: #1e3262;
      font-weight: bolder;
      margin-bottom: 12px;
    }

    .subHeader {
      max-height: 30vh;
      overflow: auto;
      font-size: 14px;
      text-align: start;
      color: #484848;
      margin-bottom: 24px;
    }

    .description {
      max-height: 30vh;
      overflow: auto;
      font-size: 16px;
      text-align: start;
      color: #484848;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .layoutImageContainer {
      width: 80vw;
      height: 80vw;

      .title {
        top: 15%;
      }

      .description {
        width: 65%;
      }
    }


    .modal {
      padding: 4vh 4vw 14vh 4vw;
      width: calc(80vw - 8vw);

      .image {
        width: 30vw;
        height: 12vh;
        top: unset;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
      }
    }
  }
}
