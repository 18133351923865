.commentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: grab;
  padding: 0 15%;

  .avatar {
    width: 103px;
    height: 103px;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 38px;

    img {
      max-height: 103px;
      max-width: 103px;
    }
  }

  .comment {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
    letter-spacing: -1px;
    color: #1e3262;
  }

  .invertedComma {
    margin: 32px 0 22px;
    width: 30px;
    height: 30px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .name {
    font-size: 19px;
    line-height: 24px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    color: #1e3262;
  }

  .jobTitle {
    margin: 6px 0 2px;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 2px;
    color: #1e3262;
  }



  @media only screen and (max-width: 768px) {
    .comment {
      font-size: 16px;
    }

    .name {
      font-size: 14px;
    }

    .jobTitle {
      font-size: 14px;
    }
  }
}
