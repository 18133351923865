.player {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url('../../assets/png/truck_volvo.png') no-repeat center;
  background-size: cover;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.4;
  }

  .playButton {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform: translate(-50%, -50%);
    fill: rgba(255, 255, 255, 0.9);
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(71, 26, 26, 0);
    border: 2px solid #FFFFFF;
    border-radius: 50px;
    width: 101px;
    height: 101px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 2px;
    }
  }

  .playerModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f9f9f9b3;
    z-index: 3;

    .playerModal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1000px;
      height: 500px;
      background-color: black;

      .closeButtonContainer {
        position: absolute;
        right: -42px;
        top: -42px;
        width: 32px;
        height: 32px;
        opacity: 0.4;
        transition: opacity ease-in 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .playerModalContainer {
      width: 100vw;



      .playerModal {
        left: 50%;
        width: 320px;
        height: 160px;
        transform: translate(-50%, -50%);
      }
    }
  }
}